import React from 'react';

export const NotFound = () => {
  return (
    <div className='container mainContainer'>
      <h1 className='title'>Error: 404</h1>
      <p>Page not found.</p>
    </div>
  );
};
